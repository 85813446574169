<template>
  <a-locale-provider :locale="locale">
    <div id="app">
      <!-- <whats-app-widget v-if="isNotAdminRoute" /> -->
      <loading-component v-if="isNotAdminRoute && loading" />
      <router-view :hidden="isNotAdminRoute && loading" />
    </div>
  </a-locale-provider>
</template>
<script>
import LoadingComponent from "@/components/LoadingComponent.vue";
// import WhatsAppWidget from "@/components/WhatsAppWidget.vue";
import { mapActions, mapState } from "vuex";
import _ from "lodash";
import esES from "ant-design-vue/lib/locale-provider/es_ES";
export default {
  // Screen width
  components: { LoadingComponent },
  data() {
    return {
      screenWidth: window.innerWidth,
      locale: esES,
    };
  },
  created() {
    this.updateLayout({
      screenWidth: window.innerWidth,
      selectedKeys: [this.$route.name],
    });
    this.screenWidth = window.innerWidth;
    window.addEventListener("resize", () => {
      this.screenWidth = window.innerWidth;
    });
  },
  mounted() {
    setTimeout(() => {
      const iframe = document.getElementById("wtt-widget-iframe");
      if (iframe) {
        iframe.style.bottom = "40px";
      }
    }, 200);
  },
  computed: {
    ...mapState(["loading"]),
    ...mapState("layout", ["darkMode"]),
    isNotAdminRoute() {
      return !(this.$route?.fullPath || "").includes("admin");
    },
  },
  methods: {
    ...mapActions("layout", ["updateLayout"]),
  },
  destroyed() {
    window.removeEventListener("resize", () => {
      this.screenWidth = window.innerWidth;
    });
  },
  watch: {
    screenWidth(newValue) {
      this.updateLayout({ screenWidth: newValue });
    },
    $route(to, from) {
      if (!_.isEqual(to, from)) {
        this.updateLayout({
          selectedKeys: [to.name],
        });
      }
    },
    darkMode: {
      handler(val, old) {
        if (val === old) return;
        if (val) {
          document.body.classList.remove("light");
        } else {
          document.body.classList.add("light");
        }
      },
      immediate: true,
    },
  },
};
</script>
<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Exo+2:wght@400;500;700&family=Roboto:wght@400;500&display=swap");
@import "assets/scss/styles.scss";
</style>
