import Vue from "vue";
import Antd from "ant-design-vue";
import VueMoment from "vue-moment";
import VueMeta from "vue-meta";
import VueLogger from "vuejs-logger";
import PerfectScrollbar from "vue2-perfect-scrollbar";
import VMask from "v-mask";
import VueHtmlToPaper from "vue-html-to-paper";
import money from "v-money";
import wysiwyg from "vue-wysiwyg";
import VueCryptojs from "vue-cryptojs";
import VueClipboard from "vue-clipboard2";

import "vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css";
import "vue-wysiwyg/dist/vueWysiwyg.css";
import "ant-design-vue/dist/antd.css";
import "animate.css";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faTicketAlt,
  faUsers,
  faHome,
  faPoll,
  faBell,
  faEnvelope,
  faEnvelopeOpen,
  faSignInAlt,
  faUserPlus,
  faUserLock,
  faUserCheck,
  faCheckCircle,
  faTimesCircle,
  faListCheck,
  faPenToSquare,
  faClipboardCheck,
  faExclamationCircle,
  faArrowRightArrowLeft,
  faCogs,
  faCog,
  faHandHoldingDollar,
  faUsersRays,
  faList,
  faUserFriends,
  faDollarSign,
  faMoneyBillTransfer,
  faPaperPlane,
  faLink,
  faBuildingColumns,
  faShare,
  faArrowsRotate,
  faUsersGear,
  faFileShield,
  faMoneyBillWave,
  faCheckDouble,
  faRotate,
  faStopwatch,
  faPersonCircleQuestion,
  faPhone,
  faFileInvoiceDollar,
  faKeyboard,
  faArrowRight,
  faArrowLeft,
  faChevronLeft,
  faChevronRight,
  faBackspace,
  faTrash,
  faTags,
  faTag,
  faQuestion,
  faExclamation,
  faPlay,
  faSun,
  faMoon,
  faPiggyBank,
  faUser,
  faScaleBalanced,
  faCoins,
  faBullhorn,
  faRectangleAd,
} from "@fortawesome/free-solid-svg-icons";
import {
  faMoneyBillAlt,
  faCalendarDays,
  faFaceFrown,
  faCheckSquare,
  faSquare,
  faCircleCheck,
  faCircle,
  faFileCode,
  faCreditCard,
} from "@fortawesome/free-regular-svg-icons";
import {
  faFacebookF,
  faInstagram,
  faLinkedinIn,
  faTwitter,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

const isProduction = process.env.NODE_ENV === "production";
const options = {
  isEnabled: true,
  logLevel: isProduction ? "error" : "debug",
  stringifyArguments: false,
  showLogLevel: true,
  showMethodName: true,
  separator: "|",
  showConsoleColors: true,
};

const moment = require("moment");
require("moment/locale/es");

Vue.use(VueLogger, options);
Vue.use(VMask);
Vue.use(money, { precision: 2 });
Vue.use(VueMoment, {
  moment,
});
Vue.use(Antd);
Vue.use(VueMeta, {
  // optional pluginOptions
  refreshOnceOnNavigation: true,
});
Vue.use(VueCryptojs);
Vue.use(VueClipboard);
library.add(faTicketAlt);
library.add(faUsers);
library.add(faMoneyBillAlt);
library.add(faHome);
library.add(faPoll);
library.add(faBell);
library.add(faEnvelope);
library.add(faEnvelopeOpen);
library.add(faSignInAlt);
library.add(faUserPlus);
library.add(faFacebookF);
library.add(faInstagram);
library.add(faLinkedinIn);
library.add(faTwitter);
library.add(faYoutube);
library.add(faUserLock);
library.add(faUserCheck);
library.add(faCalendarDays);
library.add(faFaceFrown);
library.add(faCheckCircle);
library.add(faTimesCircle);
library.add(faListCheck);
library.add(faPenToSquare);
library.add(faClipboardCheck);
library.add(faCheckSquare);
library.add(faSquare);
library.add(faExclamationCircle);
library.add(faArrowRightArrowLeft);
library.add(faCogs);
library.add(faCog);
library.add(faHandHoldingDollar);
library.add(faUsersRays);
library.add(faList);
library.add(faUserFriends);
library.add(faDollarSign);
library.add(faMoneyBillTransfer);
library.add(faCircleCheck);
library.add(faCircle);
library.add(faPaperPlane);
library.add(faLink);
library.add(faBuildingColumns);
library.add(faFileCode);
library.add(faShare);
library.add(faArrowsRotate);
library.add(faUsersGear);
library.add(faFileShield);
library.add(faCreditCard);
library.add(faMoneyBillWave);
library.add(faCheckDouble);
library.add(faRotate);
library.add(faStopwatch);
library.add(faPersonCircleQuestion);
library.add(faPhone);
library.add(faFileInvoiceDollar);
library.add(faKeyboard);
library.add(faArrowRight);
library.add(faArrowLeft);
library.add(faChevronLeft);
library.add(faChevronRight);
library.add(faBackspace);
library.add(faTrash);
library.add(faTags);
library.add(faTag);
library.add(faQuestion);
library.add(faExclamation);
library.add(faPlay);
library.add(faSun);
library.add(faMoon);
library.add(faPiggyBank);
library.add(faUser);
library.add(faScaleBalanced);
library.add(faCoins);
library.add(faBullhorn);
library.add(faRectangleAd);
Vue.component("font-awesome-icon", FontAwesomeIcon);
Vue.use(PerfectScrollbar);
Vue.use(VueHtmlToPaper);
Vue.use(wysiwyg, {
  maxHeight: "600px",
  locale: "es",
});
