import _ from "lodash";
import { path } from "../../api";
import * as actions from "../../utils/actions";
import { restClient } from "../../utils/restClient";

const initialState = {
  promotionRaffles: [],
  promotionRaffle: null,
};

export default {
  namespaced: true,
  name: "promotionRaffles",
  state: _.cloneDeep(initialState),
  mutations: {
    [actions.GET_PROMOTION_RAFFLES](state, payload = []) {
      state.promotionRaffles = payload;
    },
    [actions.GET_PROMOTION_RAFFLE](state, payload = null) {
      state.promotionRaffle = payload;
    },
    [actions.CREATE_PROMOTION_RAFFLE](state, payload = null) {
      state.promotionRaffle = payload;
    },
    [actions.UPDATE_PROMOTION_RAFFLE](state, payload = null) {
      state.promotionRaffle = payload;
    },
    [actions.RESET_PROMOTION_RAFFLE_FORM](state) {
      state.promotionRaffle = null;
    },
  },
  actions: {
    async fetchPromotionRaffles({ commit }, payload = {}) {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        try {
          const response = await restClient(
            path.admin.promotionRaffles.base,
            "GET",
            null,
            payload
          );
          commit(actions.GET_PROMOTION_RAFFLES, response.data.data);
          resolve(response);
        } catch (error) {
          commit(actions.GET_PROMOTION_RAFFLES, []);
          reject(error);
        }
      });
    },
    async fetchPromotionRaffleTickets(_, payload = {}) {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        try {
          const { raffleId, ...rest } = payload;
          const response = await restClient(
            path.admin.promotionRaffles.tickets(raffleId),
            "GET",
            null,
            rest
          );
          resolve(response);
        } catch (error) {
          reject(error);
        }
      });
    },
    async fetchPromotionRaffle({ commit }, payload) {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        try {
          const { data } = await restClient(
            path.admin.promotionRaffles.single(payload),
            "GET"
          );
          commit(actions.GET_PROMOTION_RAFFLE, data);
          resolve(data);
        } catch (error) {
          commit(actions.GET_PROMOTION_RAFFLE, null);
          reject(error);
        }
      });
    },
    async createPromotionRaffle({ commit }, payload) {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        try {
          const response = await restClient(
            path.admin.promotionRaffles.base,
            "POST",
            payload
          );
          commit(actions.CREATE_PROMOTION_RAFFLE, response?.data);
          resolve(response);
        } catch (error) {
          reject(error);
        }
      });
    },
    async updatePromotionRaffle({ commit }, payload) {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        try {
          const response = await restClient(
            path.admin.promotionRaffles.single(payload.id),
            "PUT",
            payload
          );
          commit(actions.UPDATE_PROMOTION_RAFFLE, response?.data);
          resolve(response);
        } catch (error) {
          reject(error);
        }
      });
    },
    async closePromotionRaffle(_, payload) {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        try {
          const response = await restClient(
            path.admin.promotionRaffles.single(payload),
            "DELETE"
          );
          resolve(response);
        } catch (error) {
          reject(error);
        }
      });
    },
    resetForm({ commit }) {
      commit(actions.RESET_PROMOTION_RAFFLE_FORM);
    },
  },
};
