<template>
  <div>
    <span
      class="backdrop"
      v-if="layout.breakPoint && !layout.collapsed"
      :style="{
        position: 'fixed',
        float: 'left',
        zIndex: 999,
        height: '100vh',
        left: 0,
        top: 0,
        bottom: 0,
        right: 0,
        width: '100vw',
        backgroundColor: 'rgba(0,0,0,0.5)',
        paddingTop: 0,
        transition: 'all 0.3s ease-in-out',
      }"
      @click="() => layout.breakPoint && onCollapse(true)"
    />
    <a-layout-sider
      breakpoint="lg"
      :width="256"
      :collapsed="layout.collapsed"
      :trigger="null"
      :collapsible="true"
      :collapsed-width="layout.breakPoint ? '0px' : '88px'"
      @breakpoint="onBreakPoint"
      class="sidebar-container"
      :style="{
        position: 'fixed',
        float: 'left',
        zIndex: 999,
        height: '100vh',
        left: 0,
        paddingTop: 0,
      }"
    >
      <div class="sidebar-container-logo" :class="{ mini: layout.collapsed }">
        <a-button
          v-if="layout.breakPoint"
          class="btn-close"
          @click="() => onCollapse(true)"
          shape="circle"
          icon="close"
        />
        <router-link
          :to="{ name: 'home' }"
          :style="{ display: 'flex', justifyContent: 'center' }"
        >
          <div
            alt="Logo"
            class="sidebar-logo"
            :class="{
              'sidebar-logo': !(layout.collapsed && !layout.breakPoint),
              'sidebar-logo-collapsed': layout.collapsed && !layout.breakPoint,
            }"
            @click="() => layout.breakPoint && onCollapse(true)"
          />
        </router-link>
      </div>
      <perfect-scrollbar>
        <div
          class="scroll-bar"
          :style="{
            maxHeight: 'CALC(100vh - 88.94px)',
            paddingBottom: 25,
          }"
        >
          <a-menu
            theme="dark"
            mode="inline"
            @openChange="onOpenChange"
            @select="onSelectMenuChange"
            :openKeys="layout.openKeys.filter((key) => !!key) || []"
            :selectedKeys="layout.selectedKeys || []"
            :style="{
              borderRight: 0,
              height: 'calc(100% - 16px)',
              background: 'transparent',
            }"
            :subMenuCloseDelay="0.1"
            :subMenuOpenDelay="0.1"
          >
            <a-divider
              :style="{
                margin: '10px 0',
                marginBottom: '15px',
              }"
            />
            <template v-for="menu in menus">
              <a-sub-menu
                v-if="menu.children && menu.children.length"
                :key="menu.key"
              >
                <span slot="title">
                  <component
                    v-if="menu.icon"
                    :is="menu.isFontAwesome ? 'font-awesome-icon' : 'a-icon'"
                    :icon="menu.icon"
                    :type="menu.icon"
                    :class="menu.isFontAwesome && 'anticon'"
                  />
                  <span>{{ menu.title }}</span>
                </span>
                <a-menu-item
                  v-for="submenu in menu.children"
                  :key="submenu.key"
                  @click="() => layout.breakPoint && onCollapse(true)"
                >
                  <component
                    v-if="submenu.icon"
                    :is="submenu.isFontAwesome ? 'font-awesome-icon' : 'a-icon'"
                    :icon="submenu.icon"
                    :type="submenu.icon"
                    :class="submenu.isFontAwesome && 'anticon'"
                  />
                  <span>{{ submenu.title }}</span>
                  <router-link v-if="submenu.key" :to="{ name: submenu.key }" />
                </a-menu-item>
              </a-sub-menu>

              <a-menu-item
                v-else
                @click="() => layout.breakPoint && onCollapse(true)"
                :key="menu.key"
              >
                <component
                  v-if="menu.icon"
                  :is="menu.isFontAwesome ? 'font-awesome-icon' : 'a-icon'"
                  :icon="menu.icon"
                  :type="menu.icon"
                  :class="`${menu.isFontAwesome && 'anticon'} ${
                    menu.key === 'how-to-play' ? 'rotate' : ''
                  }`"
                />
                <span>{{ menu.title }}</span>
                <router-link v-if="menu.key" :to="{ name: menu.key }" />
              </a-menu-item>
            </template>
          </a-menu>
        </div>
      </perfect-scrollbar>
      <div
        v-if="!(layout.collapsed && isMobile)"
        class="mode-container"
        :class="{ collapsed: layout.collapsed && !layout.breakPoint }"
      >
        <a-divider class="custom-separator" />
        <!-- SWITCH -->
        <div class="mode-content" style="transition: 'all 0.3s ease-in-out'">
          <label
            v-if="!(layout.collapsed && !layout.breakPoint)"
            style="transition: 'all 0.3s ease-in-out'"
          >
            <font-awesome-icon
              class="anticon"
              :icon="['fas', layout.darkMode ? 'sun' : 'moon']"
              :class="layout.darkMode ? 'dark-mode' : 'light-mode'"
            />
            <span
              v-if="layout.darkMode"
              class="slower"
              style="transition: 'all 0.3s ease-in-out'"
              >Modo claro</span
            >
            <span
              v-else
              class="slower"
              style="transition: 'all 0.3s ease-in-out'"
              >Modo oscuro</span
            >
          </label>
          <a-switch
            :loading="submiting"
            :disabled="submiting"
            class="custom-switch"
            :checked="layout.darkMode"
            @change="onChangeMode"
            @click="() => layout.breakPoint && onCollapse(true)"
          />
        </div>
      </div>
    </a-layout-sider>
  </div>
</template>
<script>
import { mapGetters, mapActions, mapState } from "vuex";
import { menus } from "@/router/client-menus.js";
import { apiLsDarkModeVariable, roles } from "../../../utils/utils";
export default {
  name: "SideBar",
  data() {
    return {
      submiting: false,
    };
  },
  created() {
    this.onCollapse(true);
  },
  methods: {
    ...mapActions("layout", ["updateLayout"]),
    ...mapActions("clientAuth", ["updateSettings"]),
    onOpenChange(openKeys) {
      const item = openKeys.pop();
      this.updateLayout({
        ...this.layout,
        openKeys: item ? [item] : [],
      });
    },
    onSelectMenuChange({ key }) {
      this.updateLayout({
        ...this.layout,
        selectedKeys: [key],
      });
    },
    onBreakPoint(broken) {
      this.updateLayout({
        ...this.layout,
        breakPoint: broken,
        collapsed: broken,
      });
    },
    onCollapse(collapsed = true) {
      this.updateLayout({
        ...this.layout,
        collapsed: collapsed,
      });
    },
    onChangeMode(checked) {
      try {
        this.submiting = true;

        if (this.loggedUser) {
          this.updateSettings({
            darkMode: checked,
          });
        }
        this.updateLayout({
          ...this.layout,
          darkMode: checked,
        });
        localStorage.setItem(apiLsDarkModeVariable, checked);
      } catch (error) {
        console.error(error);
      } finally {
        this.submiting = false;
      }
    },
  },
  computed: {
    ...mapGetters("layout", ["getLayoutDataStore"]),
    ...mapState("layout", ["screenWidth"]),
    ...mapGetters("clientAuth", ["getLoggedUser"]),
    isMobile() {
      return this.screenWidth < 768;
    },
    layout() {
      return this.getLayoutDataStore;
    },
    menus() {
      const {
        accessRoles = [],
        ecb = false,
        allm = false,
      } = this.loggedUser || {};
      return menus([
        ...accessRoles,
        ...(ecb ? [roles.client.ROLE_ECB] : []),
        ...(allm ? [roles.client.ROLE_ALLM] : []),
      ])
        .filter((menu) => !menu?.hidden)
        .filter((menu) =>
          this.loggedUser ? !["login", "register"].includes(menu.key) : true
        );
    },
    loggedUser() {
      return this.getLoggedUser;
    },
  },
};
</script>
<style lang="scss">
@import "../../../assets/scss/variable";
#app-layout {
  .sidebar-container {
    background-color: var(--sidebar-bg);
    z-index: 999;
    border-right: 1px solid var(--white-light);
    .slower {
      transition: all 0.5s ease-in-out;
    }
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0.8 !important;
      background-color: var(--sidebar-bg) !important;
      z-index: -10;
    }
    &-logo {
      position: relative;
      overflow: hidden;
      cursor: pointer;
      transition: all 0.3s;
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
      padding: 18px 10px;
      text-align: center;
      &.mini {
        padding: 14px 10px;
      }
      a.router-link-active {
        width: 100%;
      }
      .sidebar-logo {
        width: 184px;
        height: 28px;
        background-image: var(--banner-bg);
        background-position-y: center;
        background-position-x: center;
        background-size: cover;
        background-repeat: no-repeat;
        background-origin: content-box;
        padding-bottom: 6px;
        &-collapsed {
          width: 36px;
          height: 36px;
          background-image: var(--banner-bg-collapsed);
          background-position-y: center;
          background-position-x: center;
          background-size: cover;
          background-repeat: no-repeat;
          background-origin: content-box;
          padding-bottom: 6px;
        }
      }
    }
    .btn-close {
      color: var(--text) !important;
      position: absolute !important;
      z-index: 1 !important;
      top: 8px !important;
      right: 2px !important;
      background: transparent !important;
      transition: 0.5s ease-in-out;
      box-shadow: var(--white-light) 0px 0px 0px 2px !important;
      border-radius: 50%;
      border: 1px solid rgba(152, 152, 152, 0.3);
      &:hover {
        background: rgba(152, 152, 152, 0.3) !important;
        transition: 0.5s ease-in-out;
      }
      @media screen and (max-width: $screen-mobile) {
        transition: 0.5s ease-in-out;
      }
    }
    .ant-menu-item {
      font-family: "Roboto", sans-serif;
      font-weight: 600;
      text-transform: uppercase;
      font-size: 0.875rem;
      color: var(--text) !important;
      .anticon {
        &.rotate {
          transform: rotate(90deg) !important;
        }
      }
      &-selected {
        border-right: 3px solid var(--text-active);
        transition: 0.5s;
        color: var(--text-active) !important;
        background-color: transparent !important;
        .anticon {
          color: var(--text-active) !important;
        }
      }
      &-active {
        border-right: 3px solid var(--text-active);
        transition: 0.5s;
        color: var(--text-active) !important;
        background-color: var(--white-light) !important;
        .anticon {
          color: var(--text-active) !important;
        }
      }
    }
    .mode-container {
      position: fixed;
      bottom: 0;
      width: 256px;
      @media screen and (max-width: $screen-mobile) {
        bottom: 10px;
      }
      &.collapsed {
        width: 88px;
      }
      .custom-separator {
        margin-top: 10px;
        margin-bottom: 10px;
      }
      .mode-content {
        padding: 10px 10px;
        padding-left: 24px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 40px;
        width: 100%;
        label {
          font-family: "Roboto", sans-serif;
          font-weight: 600;
          text-transform: uppercase;
          font-size: 0.875rem;
          color: var(--text) !important;
          .anticon {
            font-size: 0.875rem !important;
            &.dark-mode {
              color: var(--sun) !important;
            }
            &.light-mode {
              color: var(--moon) !important;
            }
          }
          span {
            margin-left: 10px;
          }
        }
        .custom-switch {
          background-color: var(--text-active);
          border: 1px solid var(--muted-light);
          background-color: var(--moon);
          &.ant-switch-checked {
            background-color: var(--white);
            border: 1px solid var(--white-light);
            &::after {
              background-color: var(--sun);
            }
          }
        }
      }
    }
  }
}
.sidebar-container {
  .ant-menu-dark .ant-menu-item-selected .anticon + span {
    color: var(--text-active) !important;
  }
  .ant-menu.ant-menu-dark .ant-menu-item-selected,
  .ant-menu.ant-menu-dark .ant-menu-item-active,
  .ant-menu-dark .ant-menu-inline.ant-menu-sub,
  .ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected {
    background-color: rgba(255, 255, 255, 0.1) !important;
  }
}
</style>
