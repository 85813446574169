import _ from "lodash";
import { path } from "../../api";
import router from "../../router";
import * as actions from "../../utils/actions";
import localStorageService from "../../utils/localStorage";
import {
  restClient,
  restClientWithOutAuthentication,
} from "../../utils/restClient";
import { apiLsVariable, getUserData } from "../../utils/utils";

const initialState = {
  authenticated: false,
  user: null,
  token: "",
  expires_in: 0,
};

export default {
  namespaced: true,
  name: "adminAuth",
  state: _.cloneDeep(initialState),
  mutations: {
    [actions.ADMIN_LOGIN](state, payload = _.cloneDeep(initialState)) {
      Object.assign(state, payload);
    },
    [actions.ADMIN_LOGOUT](state) {
      Object.assign(state, _.cloneDeep(initialState));
    },
  },
  actions: {
    async handleLogin({ commit }, payload) {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        try {
          const { token, expires_in } = await restClientWithOutAuthentication(
            path.admin.login,
            "POST",
            payload
          );

          // SAVE TOKEN TO LOCAL STORAGE
          localStorageService.set(
            apiLsVariable,
            window.btoa(JSON.stringify({ token, expires_in }))
          );

          const user = getUserData(token);

          commit(actions.ADMIN_LOGIN, {
            user,
            token,
            expires_in,
            authenticated: true,
          });
          resolve(user);
        } catch (error) {
          commit(actions.ADMIN_LOGIN);
          reject(error);
        }
      });
    },
    async handleLogout({ commit }) {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve) => {
        try {
          await restClient(path.admin.logout, "POST");
        } catch (error) {
          console.error(error, "error");
        } finally {
          await localStorageService.remove(apiLsVariable);
          commit(actions.ADMIN_LOGOUT);
          resolve();
        }
      });
    },
    async checkSession({ commit }) {
      try {
        const session = JSON.parse(
          window.atob(localStorageService.get(apiLsVariable)) || null
        );
        if (session) {
          const userData = await getUserData(session.token);
          if (userData.module === "admin") {
            const route = path.admin.loggedUser;
            const { data: user } = await restClient(route, "POST");
            commit(actions.ADMIN_LOGIN, {
              user,
              token: session.token,
              expires_in: session.expires_in,
              authenticated: true,
            });
            // Redicrect to dashboard page
            if (["admin"].includes(router.currentRoute.name)) {
              router.push({
                name: "admin.dashboard",
              });
            }
          } else {
            commit(actions.ADMIN_LOGIN, null);
            localStorageService.remove(apiLsVariable);
          }
        } else {
          commit(actions.ADMIN_LOGIN, null);
          localStorageService.remove(apiLsVariable);
        }
      } catch (error) {
        if (error.isAxiosError && [401, 403].includes(error.response.status)) {
          commit(actions.ADMIN_LOGIN, null);
          localStorageService.remove(apiLsVariable);
        }
      }
    },
    async handleResetPassword(_, payload) {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        try {
          const { data } = await restClientWithOutAuthentication(
            path.admin.resetPassword,
            "POST",
            payload
          );
          resolve(data);
        } catch (error) {
          reject(error);
        }
      });
    },
    async handleChangePassword(_, payload) {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        try {
          const { data } = await restClient(path.admin.changePassword, "PUT", {
            ...payload,
          });
          resolve(data);
        } catch (error) {
          reject(error);
        }
      });
    },
    async handlePasswordResetCodeVerify(_, payload) {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        try {
          const response = await restClientWithOutAuthentication(
            path.admin.verifyPasswordResetCode(payload),
            "POST"
          );
          resolve(response);
        } catch (error) {
          reject(error);
        }
      });
    },
    async handlePasswordReset(_, payload) {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        try {
          const response = await restClientWithOutAuthentication(
            path.admin.passwordReset,
            "POST",
            payload
          );
          resolve(response);
        } catch (error) {
          reject(error);
        }
      });
    },
  },
  getters: {
    isAuthenticated(state) {
      return state.authenticated;
    },
    getLoggedUser: (state) => {
      return state.user;
    },
  },
};
