<template>
  <a-layout id="app-layout">
    <Teleport to="body" v-if="htmlHeader">
      <span style="display: none" v-html="htmlHeader" />
    </Teleport>
    <SideBar />
    <a-layout
      :style="{
        marginLeft: sizeMargin,
        minHeight: '100vh',
        transition: 'ease 0.2s',
      }"
    >
      <ClientHeader />
      <a-layout-content class="content-wrapper">
        <transition
          mode="out-in"
          enter-active-class="animate__animated animate__fadeIn animate__faster"
          leave-active-class="animate__animated animate__fadeOut animate__faster"
        >
          <router-view />
        </transition>
      </a-layout-content>

      <a-layout-footer class="client-footer">
        <div class="footer-content">
          <span :style="{ marginRight: '10px', fontWeight: 600 }">{{
            labels.allRightsReserved(labels.appName)
          }}</span>
          <!-- social networks -->
          <div class="social-network">
            <a v-if="getUrl('facebook')" :href="getUrl('facebook')">
              <font-awesome-icon
                :icon="['fab', 'facebook-f']"
                :class="'anticon'"
              />
            </a>
            <a v-if="getUrl('twitter')" :href="getUrl('twitter')">
              <font-awesome-icon
                :icon="['fab', 'twitter']"
                :class="'anticon'"
              />
            </a>
            <a v-if="getUrl('linkedin')" :href="getUrl('linkedin')">
              <font-awesome-icon
                :icon="['fab', 'linkedin-in']"
                :class="'anticon'"
              />
            </a>
            <a v-if="getUrl('instagram')" :href="getUrl('instagram')">
              <font-awesome-icon
                :icon="['fab', 'instagram']"
                :class="'anticon'"
              />
            </a>
            <a v-if="getUrl('youtube')" :href="getUrl('youtube')">
              <font-awesome-icon
                :icon="['fab', 'youtube']"
                :class="'anticon'"
              />
            </a>
          </div>
        </div>
        <a-divider class="custom-separator" />
        <div class="footer-content-link">
          <router-link class="footer-link" :to="{ name: 'privacy-policies' }"
            >Políticas de privacidad</router-link
          >
          <router-link
            class="footer-link"
            :to="{ name: 'terms-and-conditions' }"
            >Términos y condiciones</router-link
          >
        </div>
      </a-layout-footer>
    </a-layout>
  </a-layout>
</template>

<script>
import SideBar from "../components/layout/client/SideBar.vue";
import ClientHeader from "../components/layout/client/ClientHeader.vue";
import { mapActions, mapGetters, mapState } from "vuex";
import labels from "@/utils/labels";
import { saveCookieEncode, cookieEncodeExists } from "@/utils/utils";
import Teleport from "vue2-teleport";
import _ from "lodash";
export default {
  components: { ClientHeader, SideBar, Teleport },
  name: "AppLayout",
  beforeRouteEnter(_to, _from, next) {
    next(async (vm) => {
      await Promise.all([
        vm.getSocialNetworksConfig(),
        vm.handleGetPrivacyPoliciesTermsConditions(),
        vm.fetchHTMLHeader(),
        vm.handleGetHomeAds(),
      ]);
    });
  },
  data() {
    return {
      labels: labels,
      showed: false,
      ads: [],
      modalOpen: undefined,
    };
  },
  methods: {
    ...mapActions("ads", [
      "getHomeAds",
      "getUserAds",
      "markAsViewed",
      "markAsClicked",
    ]),
    ...mapActions("systemConfig", [
      "fetchSocialNetworksConfig",
      "getPrivacyPoliciesTermsConditions",
      "fetchHTMLHeader",
    ]),
    showReferralNotification() {
      this.$nextTick(() => {
        this.$notification.warning({
          message: this.labels.home.referralMessage,
          duration: 15,
          closable: true,
          class: "referral-notification",
        });
      });
    },
    async getSocialNetworksConfig() {
      try {
        await this.fetchSocialNetworksConfig();
      } catch (error) {
        console.error(error);
      }
    },
    async handleGetPrivacyPoliciesTermsConditions() {
      try {
        await this.getPrivacyPoliciesTermsConditions();
      } catch (error) {
        console.error(error);
      }
    },
    getUrl(name) {
      const { socialNetworks = [] } = this;
      const socialNetwork = socialNetworks.find(
        (item) => !!item?.label?.toLowerCase()?.includes(name)
      );
      if (!socialNetwork) {
        return null;
      }
      return socialNetwork.value;
    },
    showAds(ads) {
      if (!ads || ads.length === 0) {
        return;
      }
      try {
        // check if there is modal shown
        if (this.modalOpen) {
          return;
        }

        // show modal without header and footer. with body image, video or html
        const ad = _.cloneDeep(ads).shift();
        const {
          type,
          content,
          title,
          width = 800,
          height = "auto",
          position,
          link,
        } = ad;
        const isVideo = type === "video";
        const isImage = type === "image";
        const isHtml = type === "html";
        const h = this.$createElement;
        const closeBtn = h("a-icon", {
          props: { type: "close" },
          on: {
            click: () => {
              this.modalOpen?.destroy();
              this.modalOpen = undefined;
              this.$nextTick(() => {
                this.handleMarkAsViewed(_.cloneDeep(ad));
              });
            },
          },
        });
        const body = isVideo
          ? h("video", {
              class: `ads-video ${link ? "link" : ""}`,
              attrs: {
                width: this.isMobile ? "100%" : width,
                height: this.isMobile ? "auto" : height,
                autoplay: true,
                controls: true,
                loop: true,
                muted: true,
              },
              props: {
                src: content,
                autoplay: true,
                controls: true,
                loop: true,
                muted: true,
              },
              ...(link
                ? {
                    on: {
                      click: (e) => {
                        e.stopPropagation();
                        e.preventDefault();
                        this.handleMarkAsClicked(_.cloneDeep(ad));
                        window.open(link, "_blank");
                      },
                    },
                  }
                : {}),
            })
          : isImage
          ? h("img", {
              class: `ads-image ${link ? "link" : ""}`,
              attrs: {
                width: this.isMobile ? "100%" : width,
                height: this.isMobile ? "auto" : height,
                src: content,
                alt: title,
                title: title,
              },
              ...(link
                ? {
                    on: {
                      click: (e) => {
                        e.stopPropagation();
                        e.preventDefault();
                        this.handleMarkAsClicked(_.cloneDeep(ad));
                        window.open(link, "_blank");
                      },
                    },
                  }
                : {}),
            })
          : isHtml
          ? h("div", {
              class: `ads-html ${link ? "link" : ""}`,
              attrs: {
                style: `user-select: none;`,
              },
              domProps: {
                innerHTML: content,
              },
              ...(link
                ? {
                    on: {
                      click: (e) => {
                        e.stopPropagation();
                        e.preventDefault();
                        this.handleMarkAsClicked(_.cloneDeep(ad));
                        window.open(link, "_blank");
                      },
                    },
                  }
                : {}),
            })
          : null;
        this.modalOpen = this.$info({
          class: "custom-ads-modal",
          width: width,
          height: height,
          closable: false,
          content: body,
          mask: true,
          maskClosable: false,
          ...(position === "center" ? { centered: true } : {}),
          ...(position === "top"
            ? {
                width: this.isMobile ? "100%" : width,
                centered: false,
                dialogStyle: {
                  top: "0px",
                  left: "50%",
                  transform: "translateX(-50%)",
                },
              }
            : {}),
          cancelButtonProps: {
            style: {
              display: "none",
            },
          },
          okButtonProps: {
            style: {
              display: "none",
            },
          },
          keyboard: false,
          icon: () => closeBtn,
        });

        this.$nextTick(() => {
          const modalBody = document
            .querySelector(".custom-ads-modal")
            ?.querySelector(".ant-modal-confirm .ant-modal-body");
          if (modalBody) {
            modalBody.style.padding = "0px";
          }
        });
      } catch (error) {
        console.error(error);
      }
    },
    async handleMarkAsViewed(ad) {
      try {
        if (this.authenticated) {
          await this.markAsViewed(ad.id);
        }
        if (!this.authenticated || !ad?.auth) {
          saveCookieEncode(`ads-${ad.id}-viewed`, "1", 24);
        }
      } catch (error) {
        console.error(error);
      } finally {
        this.$nextTick(() => {
          this.ads = this.ads.filter((adn) => adn.id !== ad?.id);
        }, 1500);
      }
    },
    async handleMarkAsClicked(ad) {
      try {
        if (this.authenticated) {
          await this.markAsClicked(ad.id);
        }
      } catch (error) {
        console.error(error);
      }
    },
    async handleGetHomeAds() {
      try {
        const response = await this.getHomeAds();
        if (response) {
          this.ads = _.uniqBy(
            [...this.ads, ...(response?.data?.data || [])],
            "id"
          )
            .filter((ad) => !cookieEncodeExists(`ads-${ad.id}-viewed`, "1"))
            .map((ad) => ({
              ...ad,
              ...("auth" in ad ? {} : { auth: false }),
            }));
        }
      } catch (error) {
        console.error(error);
      }
    },
    async handleGetUserAds() {
      try {
        const response = await this.getUserAds();
        if (response) {
          this.ads = _.uniqBy(
            [...this.ads, ...(response?.data?.data || [])],
            "id"
          ).map((ad) => ({
            ...ad,
            ...("auth" in ad ? {} : { auth: true }),
          }));
        }
      } catch (error) {
        console.error(error);
      }
    },
  },
  computed: {
    ...mapGetters("layout", ["getLayoutDataStore"]),
    ...mapState("clientAuth", ["authenticated"]),
    ...mapState("systemConfig", ["socialNetworks"]),
    ...mapGetters({
      htmlHeader: "systemConfig/getHTMLHeader",
    }),
    ...mapGetters({
      loggedUser: "clientAuth/getLoggedUser",
    }),
    totalAds() {
      return this.ads?.length || 0;
    },
    layout() {
      return this.getLayoutDataStore;
    },
    isMobile() {
      return this.layout.screenWidth <= 600;
    },
    breakPoint() {
      return this.layout.screenWidth <= 1199;
    },
    sizeMargin() {
      return this.layout.breakPoint
        ? "0px"
        : this.layout.collapsed
        ? "88px"
        : "256px";
    },
  },
  watch: {
    authenticated() {
      if (this.loggedUser && this.loggedUser?.iru && !this.showed) {
        this.showed = true;
        this.showReferralNotification();
      }
      if (this.loggedUser) {
        this.handleGetUserAds();
      }
    },
    ads: {
      handler(val, old) {
        if (!_.isEqual(val, old)) {
          this.$nextTick(() => {
            this.showAds(_.cloneDeep(val));
          }, 1000);
        }
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss">
@import "../assets/scss/variable";
#app-layout {
  flex-direction: column;
  .content-wrapper {
    margin-top: 74px;
    min-height: calc(100vh - 84px - 74px);
    display: flex;
    width: 100% !important;
    height: auto;
    background: var(--background);
    color: var(--text);
    padding-bottom: 60px;
    @media screen and (max-width: $screen-mobile) {
      min-height: calc(100vh - 84px - 60px);
      padding-bottom: 50px;
      margin-top: 60px;
      transition: 1s ease-in-out;
    }
  }
  .client-footer {
    background-color: var(--footer-bg);
    color: var(--text);
    font-family: "Roboto", sans-serif;
    display: flex;
    width: 100% !important;
    flex-direction: column;
    position: relative;
    height: auto;
    @media screen and (max-width: $screen-mobile) {
      padding: 24px 20px;
      transition: 0.5s ease-in-out;
    }
    .footer-content {
      display: flex !important;
      justify-content: space-between !important;
      width: 100% !important;
      align-items: center;
      transition: 0.5s ease-in-out;
      .social-network {
        display: flex !important;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 10px;
        a {
          color: color(--white);
          width: 36px !important;
          height: 36px !important;
          border: 1px solid var(--text);
          text-decoration: none;
          font-weight: 400;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 50%;
          &:hover {
            transition: ease-in-out 0.3s;
            background-color: var(--hover);
          }
          .anticon {
            font-size: 20px !important;
          }
        }
      }
      @media screen and (max-width: 600px) {
        flex-direction: column-reverse;
        gap: 15px;
        text-align: center !important;
        transition: 0.5s ease-in-out;
      }
      &-link {
        .footer-link {
          color: var(--link);
          text-decoration: underline;
          &:not(:last-child) {
            padding-right: 5px;
            margin-right: 5px;
            border-right: 1px solid var(--text-muted);
          }
        }
      }
    }
    .custom-separator {
      background: var(--text-muted);
    }
  }
}
.custom-ads-modal {
  * {
    .ant-modal-content {
      background-color: var(--modal-bg);
    }
    .ads-html,
    .ads-video,
    .ads-image {
      object-fit: cover;
      &.link {
        cursor: pointer !important;
      }
    }
    .ant-modal-confirm-body {
      margin-right: 0;
      .anticon-close {
        color: var(--text);
        font-size: 18px;
        position: absolute;
        font-weight: 600 !important;
        right: 0;
        top: 0;
        margin: 10px;
        &:hover {
          color: var(--danger);
          cursor: pointer;
          font-weight: 700 !important;
        }
      }
      .ant-modal-confirm-content {
        margin: 0;
        padding: 0;
        text-align: center;
        font-size: 16px;
        color: var(--text);
      }
    }
    .ant-modal-confirm-btns {
      display: none;
    }
  }
}
.referral-notification {
  background-color: var(--notification-bg);
  .ant-notification-notice-message,
  .ant-notification-notice-description,
  .ant-notification-notice-close {
    color: var(--notification-text);
  }
}
</style>
