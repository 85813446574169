import VeeValidate, { Validator } from "vee-validate";
import moment from "moment";
import es from "vee-validate/dist/locale/es";

export function getVeeValidateLangConfig() {
  return { lang: "es", dictionary: es };
}

export function getVeeValidateInstance(Vue) {
  const { lang, dictionary } = getVeeValidateLangConfig();
  const instance = Vue.use(VeeValidate, {
    errorBagName: "veeErrors",
    fieldsBagName: "veeFields",
    classes: true,
    classNames: {
      valid: "is-valid",
      invalid: "is-invalid",
    },
    events: "change|blur",
  });
  Validator.localize(lang, dictionary);

  // Phone Number Validation
  const phoneNumberRegEx = /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/;

  // Password Validation
  const passwordRegExp = /(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]/;

  // Style width Validation px|%|auto etc.
  const styleSizeRegExp =
    /^(auto|inherit|initial|unset|0|[0-9]+(px|em|rem|vw|vh|%)|calc\(.+\))$/;

  const comparePasswords = (obj) => obj;

  Validator.extend("validatePassword", {
    getMessage: (input) =>
      `${input} debe contener al menos una letra mayúscula, una minúscula y un numero`,
    validate: (value) => passwordRegExp.test(value),
  });

  Validator.extend("confirmPassword", {
    getMessage: () => "las contraseñas no coinciden",
    validate: (value) => comparePasswords(value),
  });

  Validator.extend("ageOver18", {
    getMessage: () => "La edad debe ser mayor que 18 años",
    validate: (value) => {
      const now = moment().format("YYYY-MM-DDTHH:mm:ss");
      value = moment(value).format("YYYY-MM-DDTHH:mm:ss");
      const diffAge = moment(now).diff(value, "years");
      return diffAge >= 18;
    },
  });

  Validator.extend("validTime", {
    validate: (value) => {
      return value ? moment(value, "hh:mm A", true).isValid() : true;
    },
  });

  Validator.extend("phoneNumber", {
    getMessage: () => "Numero de teléfono incorrecto",
    validate: (value) => phoneNumberRegEx.test(value),
  });

  Validator.extend("validPlayNumber", {
    getMessage: () => "El número de jugada debe estar entre 00 y 99",
    validate: (value) => {
      const numbers = value?.split("-")?.filter((n) => n !== "") || [];
      return numbers?.every((n) => {
        if (n === "0") return false;
        return n >= 0 && n <= 99;
      });
    },
  });

  Validator.extend("validPlay", {
    getMessage: () =>
      "La Jugada no es válida, favor de verificar, ejemplo: 00-00-00, 00-00, 00",
    validate: (value) => {
      const numbers = value?.split("-") || [];

      if (numbers?.length === 0) {
        return false;
      } else if (numbers?.length === 1) {
        return numbers[0] >= 0 && numbers[0] <= 99;
      } else if (numbers?.length === 2) {
        return (
          numbers?.filter((n) => !!n)?.length === 2 &&
          numbers[0] >= 0 &&
          numbers[0] <= 99 &&
          numbers[1] >= 0 &&
          numbers[1] <= 99
        );
      } else if (numbers?.length === 3) {
        return (
          numbers?.filter((n) => !!n)?.length === 3 &&
          numbers[0] >= 0 &&
          numbers[0] <= 99 &&
          numbers[1] >= 0 &&
          numbers[1] <= 99 &&
          numbers[2] >= 0 &&
          numbers[2] <= 99
        );
      }

      return false;
    },
  });

  Validator.extend("validPale", {
    getMessage: () =>
      "El Pale no es válida, favor de verificar, ejemplo: 00-00",
    validate: (value) => {
      const numbers = value?.split("-") || [];

      return (
        numbers?.filter((n) => !!n)?.length === 2 &&
        numbers[0] >= 0 &&
        numbers[0] <= 99 &&
        numbers[1] >= 0 &&
        numbers[1] <= 99
      );
    },
  });

  // min number validation
  Validator.extend("minNumber", {
    getMessage: (field, params) =>
      `El campo ${field} debe ser mayor o igual a ${params[0]}`,
    validate: (value, [min]) => value >= min,
  });

  // max number validation
  Validator.extend("maxNumber", {
    getMessage: (field, params) =>
      `El campo ${field} debe ser menor o igual a ${params[0]}`,
    validate: (value, [max]) => value <= max,
  });

  // credit card expiration date validation (MM/YY)
  Validator.extend("creditCardExpirationDate", {
    getMessage: () =>
      "La fecha de expiración de la tarjeta no es válida. MM/YY",
    validate: (value) => {
      if (!value) return false;
      const [month, year] = value.split("/");
      const currentYear = new Date().getFullYear();
      const currentMonth = new Date().getMonth() + 1;
      return (
        month >= 1 &&
        month <= 12 &&
        year >= currentYear.toString().slice(-2) &&
        year <= (currentYear + 20).toString().slice(-2) &&
        (year > currentYear.toString().slice(-2) || month >= currentMonth)
      );
    },
  });

  // style width validation
  Validator.extend("styleWidth", {
    getMessage: () => "El ancho no es válido",
    validate: (value) => styleSizeRegExp.test(value),
  });

  // style height validation
  Validator.extend("styleHeight", {
    getMessage: () => "La altura no es válida",
    validate: (value) => styleSizeRegExp.test(value),
  });

  return instance;
}
