import _ from "lodash";
import { path } from "../../api";
import * as actions from "../../utils/actions";
import {
  restClient,
  restClientWithOutAuthentication,
} from "../../utils/restClient";

const initialState = {
  ads: [],
  ad: null,
};

export default {
  namespaced: true,
  name: "ads",
  state: _.cloneDeep(initialState),
  mutations: {
    [actions.GET_ADS](state, payload = []) {
      state.ads = payload;
    },
    [actions.GET_AD](state, payload = null) {
      state.ad = payload;
    },
    [actions.CREATE_AD](state, payload = null) {
      state.ad = payload;
    },
    [actions.UPDATE_AD](state, payload = null) {
      state.ad = payload;
    },
    [actions.RESET_AD_FORM](state) {
      state.ad = null;
    },
  },
  actions: {
    async fetchAds({ commit }, payload = {}) {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        try {
          const response = await restClient(
            path.admin.ads.base,
            "GET",
            null,
            payload
          );
          commit(actions.GET_ADS, response.data.data);
          resolve(response);
        } catch (error) {
          commit(actions.GET_ADS, []);
          reject(error);
        }
      });
    },
    async fetchAd({ commit }, payload) {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        try {
          const { data } = await restClient(
            path.admin.ads.single(payload),
            "GET"
          );
          commit(actions.GET_AD, data);
          resolve(data);
        } catch (error) {
          commit(actions.GET_AD, null);
          reject(error);
        }
      });
    },
    async createAd({ commit }, payload) {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        try {
          const { formData } = payload;
          const response = await restClient(
            path.admin.ads.base,
            "POST",
            formData,
            {},
            { "Content-Type": "multipart/form-data" }
          );
          commit(actions.CREATE_AD, response?.data);
          resolve(response);
        } catch (error) {
          reject(error);
        }
      });
    },
    async updateAd({ commit }, payload) {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        try {
          const { formData, id } = payload;
          const response = await restClient(
            path.admin.ads.single(id),
            "POST",
            formData,
            {},
            { "Content-Type": "multipart/form-data" }
          );
          commit(actions.UPDATE_AD, response?.data);
          resolve(response);
        } catch (error) {
          reject(error);
        }
      });
    },
    async deleteAd(_, payload) {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        try {
          const response = await restClient(
            path.admin.ads.single(payload),
            "DELETE"
          );
          resolve(response);
        } catch (error) {
          reject(error);
        }
      });
    },
    async getHomeAds() {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        try {
          const response = await restClientWithOutAuthentication(
            path.client.homeAds,
            "GET"
          );
          resolve(response);
        } catch (error) {
          reject(error);
        }
      });
    },
    async getUserAds() {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        try {
          const response = await restClient(path.client.ads.base, "GET");
          resolve(response);
        } catch (error) {
          reject(error);
        }
      });
    },
    async markAsViewed(_, payload) {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        try {
          const response = await restClient(
            path.client.ads.viewed(payload),
            "PUT"
          );
          resolve(response);
        } catch (error) {
          reject(error);
        }
      });
    },
    async markAsClicked(_, payload) {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        try {
          const response = await restClient(
            path.client.ads.clicked(payload),
            "PUT"
          );
          resolve(response);
        } catch (error) {
          reject(error);
        }
      });
    },
    resetForm({ commit }) {
      commit(actions.RESET_AD_FORM);
    },
  },
};
