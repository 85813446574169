// MENUS TYPES FOR LAYOUT
export const UPDATE_LAYOUT = "LAYOUT/UPDATE_LAYOUT/LOCAL";
export const GET_LAYOUT = "LAYOUT/GET_LAYOUT/LOCAL";

// TYPES FOR LOADING LANDING PAGE
export const SET_LOADING = "LOADING/SET_LOADING/LOCAL";

// AUTH TYPES
export const ADMIN_LOGIN = "ADMIN/AUTH/LOGIN/API";
export const ADMIN_LOGOUT = "ADMIN/AUTH/LOGOUT/API";

// AUTH TYPES
export const CLIENT_LOGIN = "CLIENT/AUTH/LOGIN/API";
export const CLIENT_BALANCE = "CLIENT/BALANCE/API";
export const CLIENT_LOGOUT = "CLIENT/AUTH/LOGOUT/API";
export const CLIENT_SET_IS_FROM_REGISTER =
  "CLIENT/AUTH/SET_IS_FROM_REGISTER/LOCAL";

// PROVINCES TYPES
export const GET_PROVINCES = "PROVINCES/GET_PROVINCES/API";

// COUNTRY LIST
export const GET_COUNTRY_LIST = "COUNTRY_LIST/GET_COUNTRY_LIST/API";

// USER ADMINS TYPES
export const GET_ADMINS = "ADMIN/GET_ADMINS/API";
export const GET_ADMIN = "ADMIN/GET_ADMIN/API";
export const CREATE_ADMIN = "ADMIN/CREATE_ADMIN/API";
export const UPDATE_ADMIN = "ADMIN/UPDATE_ADMIN/API";
export const DELETE_ADMIN = "ADMIN/DELETE_ADMIN/API";
export const RESET_ADMIN_FORM = "ADMIN/RESET_FORM/LOCAL";

// ROLES TYPES
export const GET_ROLES = "ADMIN/GET_ROLES/API";

// USERS TYPES
export const GET_USERS = "ADMIN/GET_USERS/API";
export const GET_USER = "ADMIN/GET_USER/API";
export const CREATE_USER = "ADMIN/CREATE_USER/API";
export const UPDATE_USER = "ADMIN/UPDATE_USER/API";
export const DELETE_USER = "ADMIN/DELETE_USER/API";
export const RESET_USER_FORM = "ADMIN/RESET_USER_FORM/LOCAL";

// LOTTERIES TYPES
export const GET_LOTTERIES = "ADMIN/GET_LOTTERIES/API";
export const GET_LOTTERY = "ADMIN/GET_LOTTERY/API";
export const CREATE_LOTTERY = "ADMIN/CREATE_LOTTERY/API";
export const UPDATE_LOTTERY = "ADMIN/UPDATE_LOTTERY/API";
export const DELETE_LOTTERY = "ADMIN/DELETE_LOTTERY/API";
export const RESET_LOTTERY_FORM = "ADMIN/RESET_LOTTERY_FORM/LOCAL";

// RAFFLES TYPES
export const GET_RAFFLES = "ADMIN/GET_RAFFLES/API";
export const GET_RAFFLE = "ADMIN/GET_RAFFLE/API";
export const CREATE_RAFFLE = "ADMIN/CREATE_RAFFLE/API";
export const UPDATE_RAFFLE = "ADMIN/UPDATE_RAFFLE/API";
export const DELETE_RAFFLE = "ADMIN/DELETE_RAFFLE/API";
export const RESET_RAFFLE_FORM = "ADMIN/RESET_RAFFLE_FORM/LOCAL";

// CART TYPES
export const GET_CART_PLAYS = "CART/GET_PLAYS/API";
export const RESET_CART_PLAY_FORM = "CART/PLAY_FORM/LOCAL";

// ORDER TYPES
export const GET_ORDERS = "ORDERS/GET_ORDERS/API";
export const GET_ORDER = "ORDERS/GET_ORDER/API";

// TRANSACTIONS
export const GET_TRANSACTIONS = "ADMIN/GET_TRANSACTIONS/API";
export const GET_TRANSACTION = "ADMIN/GET_TRANSACTION/API";
export const PROCESS_TRANSACTION = "ADMIN/PROCESS_TRANSACTION/API";
export const CANCEL_TRANSACTION = "ADMIN/CANCEL_TRANSACTION/API";
export const APPROVE_TRANSACTION = "ADMIN/APPROVE_TRANSACTION/API";
export const GENERATE_UD_COMMISSION_TRANSACTION =
  "ADMIN/GENERATE_UD_COMMISSION_TRANSACTION/API";
export const REJECT_TRANSACTION = "ADMIN/REJECT_TRANSACTION/API";
export const RESET_TRANSACTION_FORM = "ADMIN/RESET_TRANSACTION_FORM/LOCAL";
export const REQUEST_CANCEL_WITHDRAW = "CLIENT/REQUEST_CANCEL_WITHDRAW/API";

// BLOCKS TYPES
export const GET_BLOCKS = "ADMIN/GET_BLOCKS/API";
export const GET_BLOCK = "ADMIN/GET_BLOCK/API";
export const CREATE_BLOCK = "ADMIN/CREATE_BLOCK/API";
export const UPDATE_BLOCK = "ADMIN/UPDATE_BLOCK/API";
export const DELETE_BLOCK = "ADMIN/DELETE_BLOCK/API";
export const RESET_BLOCK_FORM = "ADMIN/RESET_BLOCK_FORM/LOCAL";

// PROMOTION RAFFLES TYPES
export const GET_PROMOTION_RAFFLES = "ADMIN/GET_PROMOTION_RAFFLES/API";
export const GET_PROMOTION_RAFFLE = "ADMIN/GET_PROMOTION_RAFFLE/API";
export const CREATE_PROMOTION_RAFFLE = "ADMIN/CREATE_PROMOTION_RAFFLE/API";
export const UPDATE_PROMOTION_RAFFLE = "ADMIN/UPDATE_PROMOTION_RAFFLE/API";
export const CLOSE_PROMOTION_RAFFLE = "ADMIN/CLOSE_PROMOTION_RAFFLE/API";
export const RESET_PROMOTION_RAFFLE_FORM =
  "ADMIN/RESET_PROMOTION_RAFFLE_FORM/LOCAL";

// PROMOTIONS TYPES
export const GET_PROMOTIONS = "ADMIN/GET_PROMOTIONS/API";
export const GET_PROMOTION = "ADMIN/GET_PROMOTION/API";
export const CREATE_PROMOTION = "ADMIN/CREATE_PROMOTION/API";
export const UPDATE_PROMOTION = "ADMIN/UPDATE_PROMOTION/API";
export const DELETE_PROMOTION = "ADMIN/DELETE_PROMOTION/API";
export const RESET_PROMOTION_FORM = "ADMIN/RESET_PROMOTION_FORM/LOCAL";

// PROMOTIONS TYPES
export const GET_DEPOSIT_ACCOUNT_GROUPS =
  "ADMIN/GET_DEPOSIT_ACCOUNT_GROUPS/API";
export const GET_DEPOSIT_ACCOUNT_GROUP = "ADMIN/GET_DEPOSIT_ACCOUNT_GROUP/API";
export const CREATE_DEPOSIT_ACCOUNT_GROUP =
  "ADMIN/CREATE_DEPOSIT_ACCOUNT_GROUP/API";
export const UPDATE_DEPOSIT_ACCOUNT_GROUP =
  "ADMIN/UPDATE_DEPOSIT_ACCOUNT_GROUP/API";
export const DELETE_DEPOSIT_ACCOUNT_GROUP =
  "ADMIN/DELETE_DEPOSIT_ACCOUNT_GROUP/API";
export const RESET_DEPOSIT_ACCOUNT_GROUP_FORM =
  "ADMIN/RESET_DEPOSIT_ACCOUNT_GROUP_FORM/LOCAL";

// COMMISSIONS TYPES
export const GENERATE_COMMISSIONS_REPORT = "ADMIN/COMMISSIONS_REPORT/API";
export const PAID_COMMISSIONS = "ADMIN/PAID_COMMISSIONS/API";

// SOCIAL NETWORKS TYPES
export const GET_SOCIAL_NETWORKS = "ADMIN/GET_SOCIAL_NETWORKS/API";
export const GET_WHATSAPP = "ADMIN/GET_WHATSAPP/API";
export const GET_ROULETTE_STATUS = "ADMIN/GET_ROULETTE_STATUS/API";
export const GET_CRYPTAPI_CONFIG = "CLIENT/CRYPTAPI_CONFIG/API";
export const GET_BANKS = "CLIENT/GET_BANKS/API";
export const GET_COUNTRIES = "CLIENT/GET_COUNTRIES/API";
export const GET_TIMEZONES = "CLIENT/GET_TIMEZONES/API";
export const GET_BANKS_ACCOUNTS = "CLIENT/GET_BANKS_ACCOUNTS/API";
export const GET_PRIVACY_POLICIES_TERMS_CONDITIONS =
  "CLIENT/GET_PRIVACY_POLICIES_TERMS_CONDITIONS/API";
export const GET_WELCOME_PAGE = "CLIENT/GET_WELCOME_PAGE/API";
export const GET_HTML_HEADER = "CLIENT/GET_HTML_HEADER/API";
export const GET_CANCEL_TICKET_TIMEOUT_CONFIG =
  "CLIENT/GET_CANCEL_TICKET_TIMEOUT_CONFIG/API";

// COMMISSION PROFILES TYPES
export const GET_COMMISSION_PROFILES = "ADMIN/GET_COMMISSION_PROFILES/API";
export const GET_COMMISSION_PROFILE = "ADMIN/GET_COMMISSION_PROFILE/API";
export const CREATE_COMMISSION_PROFILE = "ADMIN/CREATE_COMMISSION_PROFILE/API";
export const UPDATE_COMMISSION_PROFILE = "ADMIN/UPDATE_COMMISSION_PROFILE/API";
export const DELETE_COMMISSION_PROFILE = "ADMIN/DELETE_COMMISSION_PROFILE/API";
export const RESET_COMMISSION_PROFILE_FORM =
  "ADMIN/RESET_COMMISSION_PROFILE_FORM/LOCAL";

// TRANSACTION PROFILES TYPES
export const GET_TRANSACTION_PROFILES = "ADMIN/GET_TRANSACTION_PROFILES/API";
export const GET_TRANSACTION_PROFILE = "ADMIN/GET_TRANSACTION_PROFILE/API";
export const CREATE_TRANSACTION_PROFILE =
  "ADMIN/CREATE_TRANSACTION_PROFILE/API";
export const UPDATE_TRANSACTION_PROFILE =
  "ADMIN/UPDATE_TRANSACTION_PROFILE/API";
export const DELETE_TRANSACTION_PROFILE =
  "ADMIN/DELETE_TRANSACTION_PROFILE/API";
export const RESET_TRANSACTION_PROFILE_FORM =
  "ADMIN/RESET_TRANSACTION_PROFILE_FORM/LOCAL";

// USER BANK ACCOUNTS TYPES
export const GET_USER_BANK_ACCOUNTS = "ADMIN/GET_USER_BANK_ACCOUNTS/API";

// LOTTERIES CLOSING SCHEDULE TYPES
export const GET_LOTTERIES_SCHEDULES = "ADMIN/GET_LOTTERIES_SCHEDULES/API";
export const GET_LOTTERIES_SCHEDULE = "ADMIN/GET_LOTTERIES_SCHEDULE/API";
export const CREATE_LOTTERIES_SCHEDULE = "ADMIN/CREATE_LOTTERIES_SCHEDULE/API";
export const UPDATE_LOTTERIES_SCHEDULE = "ADMIN/UPDATE_LOTTERIES_SCHEDULE/API";
export const DELETE_LOTTERIES_SCHEDULE = "ADMIN/DELETE_LOTTERIES_SCHEDULE/API";
export const RESET_LOTTERIES_SCHEDULE_FORM =
  "ADMIN/RESET_LOTTERIES_SCHEDULE_FORM/LOCAL";

// NOTIFICATIONS
export const GET_USER_NOTIFICATIONS = "USER/GET_USER_NOTIFICATIONS/API";
export const RESET_USER_NOTIFICATIONS = "USER/RESET_USER_NOTIFICATIONS/API";
export const REGISTER_USER_TOKEN = "USER/REGISTER_USER_TOKEN/API";

// ADS
export const GET_ADS = "ADMIN/GET_ADS/API";
export const GET_AD = "ADMIN/GET_AD/API";
export const CREATE_AD = "ADMIN/CREATE_AD/API";
export const UPDATE_AD = "ADMIN/UPDATE_AD/API";
export const DELETE_AD = "ADMIN/DELETE_AD/API";
export const RESET_AD_FORM = "ADMIN/RESET_AD_FORM/LOCAL";
